import { projectFirestore } from "../../../../firebase/config";
import {user,role} from '../../../../Methods/Utils'
 
const store_data_in_db = async(data) => {
    try{
        const doc = projectFirestore.collection('Applications').doc('QEJ7GN2tn8RX8WDJUJd5')
        .collection('Forms').doc()
        await doc.set({
         ...data,
         uid:doc.id
        })
        return `https://rosmanlog.co.il/computer-service/${doc.id}`
    }catch(err){
        throw new Error(err)
    }
}

const get_data_from_db_by_filters = async(filters) => {
    let docs
    if(filters.status!='הכל'){
        docs = await projectFirestore.collection('Applications').doc('QEJ7GN2tn8RX8WDJUJd5')
       .collection('Forms').where('month','==',filters.month).where('year','==',filters.year).where('status','==',filters.status)
       .orderBy('created_at','desc').get()
    }else{
        docs = await projectFirestore.collection('Applications').doc('QEJ7GN2tn8RX8WDJUJd5')
        .collection('Forms').where('month','==',filters.month).where('year','==',filters.year)
        .orderBy('created_at','desc').get()
    }

    return docs.docs.map(doc=>doc.data())
}

const update_status_and_comment = async(error) => {
    await projectFirestore.collection('Applications').doc('QEJ7GN2tn8RX8WDJUJd5')
   .collection('Forms').doc(error.uid)
   .set({
    status:error.status,
    comment:error.comment
   },{merge:true})
}

const get_last_open_calls = async() => {
    try{
         if(user.value && role.value >= 99){
             const docs = await projectFirestore.collection('Applications').doc('QEJ7GN2tn8RX8WDJUJd5').collection('Forms')
            .where('status','in',['פתוח','בטיפול']).orderBy('created_at','desc').get()
            return docs.docs.map(doc => doc.data())
         }else if(user.value && user.value?.branche){
            const docs = await projectFirestore.collection('Applications').doc('QEJ7GN2tn8RX8WDJUJd5').collection('Forms')
            .where('branche','==',user.value.branche).where('status','in',['פתוח','בטיפול']).orderBy('created_at','desc').get()
            return docs.docs.map(doc => doc.data())
         }
         return []
    }catch(err){
        throw new Error(err)
    }
}

const get_branches_list_names = async() => {
    try{
        const docs = await projectFirestore.collection('Shivuk').doc('Shivuk').collection('Branches').get()
        return docs.docs.map(doc=>doc.data().name)
    }catch(err){
        throw new Error(err)
    }
}

const get_calls_by_filters_data = async(filters) => {
    try{
        let query = projectFirestore.collection('Applications')
        .doc('QEJ7GN2tn8RX8WDJUJd5')
        .collection('Forms');

        // Filter by selected_from_date and selected_to_date if they are not empty
        if (filters.selected_from_date !== '') {
            const fromDate = new Date(filters.selected_from_date);
            fromDate.setHours(0, 0, 0, 0); // Set start of the day for from_date
            query = query.where('created_at', '>=', fromDate);
        }
        if (filters.selected_to_date !== '') {
            const toDate = new Date(filters.selected_to_date);
            toDate.setHours(23, 59, 59, 999); // Set end of the day for to_date
            query = query.where('created_at', '<=', toDate);
        }

        // Filter by selected_branche if it is not set to 'הכל'
        if (filters.selected_branche !== 'הכל') {
            query = query.where('branche', '==', filters.selected_branche);
        }

        // Filter by selected_error if it is not set to 'הכל'
        if (filters.selected_error !== 'הכל') {
            query = query.where('error_type', '==', filters.selected_error);
        }
        if (filters.selected_status !== 'הכל') {
            query = query.where('status', '==', filters.selected_status);
        }

        // Execute the query
        const docs = await query.get();

        return docs.docs.map(doc => doc.data())
        
    }catch(err){
        throw new Error(err)
    }
}

const update_call_status_in_db = async(new_data) => {
    try{
        await projectFirestore.collection('Applications').doc('QEJ7GN2tn8RX8WDJUJd5')
        .collection('Forms').doc(new_data.uid)
        .set({
            status:new_data.status,
        },{merge:true})
    }catch(err){
        throw new Error(err)
    }
}

const get_services_people_from_db = async() => {
    try{
        const docs = await projectFirestore.collection('Applications')
        .doc('QEJ7GN2tn8RX8WDJUJd5').collection('ServisecPeople').get()
        
        return docs.docs.map(doc => doc.data())
    }catch(err){
        throw new Error(err)
    }
}

const get_services_people_from_db_maped = async() => {
    try{
        const map = new Map()
        const docs = await projectFirestore.collection('Applications')
        .doc('QEJ7GN2tn8RX8WDJUJd5').collection('ServisecPeople').get()
        
        const data =  docs.docs.map(doc => doc.data())
        data.forEach(d => map.set(d.type,d.name))
        return map
    }catch(err){
        throw new Error(err)
    }
}

const cancel_or_close_call_in_db = async(status,call) =>{
    try{
        await projectFirestore.collection('Applications').doc('QEJ7GN2tn8RX8WDJUJd5')
        .collection('Forms').doc(call.uid)
        .set({
            status
        },{merge:true})
    }catch(err){
        throw new Error(err)
    }
}  



const add_preson_data_to_db = async(person_data) => {
    try{
        const doc = projectFirestore.collection('Applications')
        .doc('QEJ7GN2tn8RX8WDJUJd5').collection('ServisecPeople').doc()

        console.log(person_data);
        console.log(doc.id);
        const new_person = {
            uid:doc.id,
            ...person_data
        }
        await doc.set(new_person)

        return new_person
    }catch(err){
        throw new Error(err)
    }
}

const edit_preson_data_to_db = async(person_data) => {
    try{
        await projectFirestore.collection('Applications')
        .doc('QEJ7GN2tn8RX8WDJUJd5').collection('ServisecPeople').doc(person_data.uid)
        .set(person_data,{merge:true})
    }catch(err){
        throw new Error(err)
    }
}

const delete_preson_data_from_db = async(person_uid) => {
    try{
        await projectFirestore.collection('Applications')
        .doc('QEJ7GN2tn8RX8WDJUJd5').collection('ServisecPeople').doc(person_uid).delete()
    }catch(err){
        throw new Error(err)
    }
}

const get_service_person_by_error_type = async(error_type) => {
    try {
        const docs = await projectFirestore.collection('Applications')
          .doc('QEJ7GN2tn8RX8WDJUJd5').collection('ServisecPeople')
          .where('type', '==', error_type).limit(1).get();
        if (!docs.empty) {
          return docs.docs[0].data();
        } else {
          console.log('No documents found matching the criteria.');
          return null; // Or handle as appropriate
        }
      } catch (error) {
        console.error('Error fetching document:', error);
        // Handle the error appropriately
        return null; // Or throw the error, depending on your error handling strategy
      }
}

const get_service_call_detail_from_db = async(uid) => {
    try{
        const docRef = await projectFirestore.collection('Applications')
        .doc('QEJ7GN2tn8RX8WDJUJd5').collection('Forms').doc(uid).get();

        if (docRef.exists) {
            // The document exists, you can safely access its data.
            return docRef.data();
        } else {
            // The document does not exist.
            console.log('No such document!');
            throw new Error('קריאה זו לא קיימת!')
        }
    }catch(err){
        throw new Error(err)
    }
}

const update_status_service_call_in_db = async(uid,new_status) => {
    try{
       await projectFirestore.collection('Applications')
        .doc('QEJ7GN2tn8RX8WDJUJd5').collection('Forms').doc(uid)
        .set({
            status:new_status
        },{merge:true})
    }catch(err){
        throw new Error(err)
    }
}

export{
    store_data_in_db,
    get_data_from_db_by_filters,
    update_status_and_comment,
    get_last_open_calls,
    get_branches_list_names,
    get_calls_by_filters_data,
    update_call_status_in_db,
    get_services_people_from_db,
    add_preson_data_to_db,
    delete_preson_data_from_db,
    edit_preson_data_to_db,
    get_service_person_by_error_type,
    get_service_call_detail_from_db,
    update_status_service_call_in_db,
    get_services_people_from_db_maped,
    cancel_or_close_call_in_db
}