<template>
    <div class="services-people">
        <div class="services-people-title">
            <h3>ניהול אנשי שירות</h3>
        </div>
        <div class="services-people-add-btn">
            <el-button @click="show_add_service_person_modal=true" type="success">הוספת איש שירות</el-button>
        </div>
        <div v-loading="is_panding" class="services-people-content">
            <table>
                <tr>
                    <th>סוג</th>
                    <th>שם</th>
                    <th>טלפון</th>
                    <th>מייל</th>
                    <th>פעולות</th>
                </tr>
                <h2 v-if="!services_people.length">לא קיימות רשומות</h2>
           
                <template v-for="{ type,name,phone,email,uid} in services_people" :key="uid">
                    <tr>
                        <td>{{type}}</td>
                        <td>{{name}}</td>
                        <td>{{phone}}</td>
                        <td>{{email}}</td>
                        <td>
                            <i @click="handle_delete_person(name,uid)" class="material-icons icon edit">close</i>
                            <i @click="handle_edit_person({ type,name,phone,email,uid})" class="material-icons icon close">edit</i>
                        </td>
                    </tr>
                </template>
            </table>
        </div>

        <AddServicePersonModal 
            v-if="show_add_service_person_modal"
            @close="show_add_service_person_modal = false; selected_edit_person = null"
            :selected_edit="selected_edit_person"
            :errors_options="errors_options"
            @submit="handle_submit"
            @submit_edit="handle_submit_edit"
        />
    </div>
</template>

<script>
import { ref,defineAsyncComponent } from 'vue';
import {get_services_people_from_db,add_preson_data_to_db,delete_preson_data_from_db,edit_preson_data_to_db} from '../../scripts/scripts'
import { get_errors_from_db } from '../../../../../Methods/rosman_shivuk_funcs';
import {slide_pop_error,alert_confirm} from '../../../../../Methods/Msgs'


export default {
    components:{
        AddServicePersonModal:defineAsyncComponent(() => import('./AddServicePersonModal.vue')),
    },
    setup () {
        
        const show_add_service_person_modal = ref(false)
        const selected_edit_person = ref(null)

        const services_people = ref([])
        const is_panding = ref(false)

        const errors_options = ref([])

     
        const handle_submit = async(person_data) => {
            try{
                is_panding.value = true
                const new_person = await add_preson_data_to_db(person_data)
                services_people.value.unshift(new_person)
                show_add_service_person_modal.value = false
                is_panding.value = false
            }catch(err){
                is_panding.value = false
                console.error(err.message);
                slide_pop_error(err.message)
            }
        }

        const handle_submit_edit = async(person_data) => {
            try{
                is_panding.value = true
                await edit_preson_data_to_db(person_data)
                const index = services_people.value.findIndex(p => p.uid == person_data.uid)
                services_people.value[index] = person_data
                show_add_service_person_modal.value = false
                selected_edit_person.value = null
                is_panding.value = false
            }catch(err){
                is_panding.value = false
                console.error(err.message);
                slide_pop_error(err.message)
            }
        }

        const handle_edit_person = async(person_data) => {
            try{    
                selected_edit_person.value = JSON.parse(JSON.stringify(person_data))
                show_add_service_person_modal.value = true
            }catch(err){
                console.error(err.message);
                slide_pop_error(err.message)

            }
        }

        const handle_delete_person = (name,uid) => {
            alert_confirm(`למחוק את ${name}?`)
            .then(async res => {
                if(res.isConfirmed){
                    try{
                        is_panding.value = true
                        const index = services_people.value.findIndex(p => p.uid == uid)
                        if(index != -1){
                            services_people.value.splice(index,1)
                            await delete_preson_data_from_db(uid)
                            is_panding.value = false
                        }

                    }catch(err){
                        is_panding.value = false
                        console.error(err.message)
                        slide_pop_error(err.message)
                    }
                }
            })
        }

        const init = async() => {
            try{
                is_panding.value = true
                if(!services_people.length){
                    services_people.value = await get_services_people_from_db()
                }
                if(!errors_options.length){
                    const res = await get_errors_from_db()
                    errors_options.value =  ['הכל',...res.map(err => err.name)]
                }
                is_panding.value = false
            }catch(err){
                is_panding.value = false
                console.error(err.message);
            }
        }


        init()

        return {
            services_people,
            is_panding,
            errors_options,
            show_add_service_person_modal,
            handle_submit,
            handle_delete_person,
            handle_edit_person,
            handle_submit_edit,
            selected_edit_person
        }
    }
}
</script>

<style scoped>
    .services-people{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    .services-people-title,.services-people-add-btn{
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    .services-people-content{
        width: 100%;
        flex: 1;
        overflow-y: auto;
    }
    table {
        border-collapse: collapse;
        width: 100%;
    }
    table td, table th {
        border: 1px solid #ddd;
        padding: 2px;       
    }
    table tr:nth-child(even){background-color: #f2f2f2;}
    table tr:nth-child(odd){background-color: #e7e6e6;}
    table tr:hover {background-color: #ddd;}
    table td{
        text-align: center;
        color: var(--main);
        font-size: 18px;
    }
    table th {
        padding-top: 2px;
        padding-bottom: 2px;
        text-align: center;
        background-color: var(--purple);
        color: white;
        position: sticky;
        top: 0;
        font-size: 20px;
    }

    .icon{
        user-select: none;
        cursor: pointer;
        margin: 0 5px;
    }
    .icon.edit{
        color: var(--danger);
    }
    .icon.close{
        color: var(--warning);
    }
</style>